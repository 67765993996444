import React, { ReactNode } from 'react';

import './CollectionItemStatus.scss';

export type CollectionItemStatusProps = {
	children: ReactNode,
	label: string
};

export function CollectionItemStatus(props: CollectionItemStatusProps) {
	const { children, label } = props;

	return (
		<div className="collection-item-status">
			{children}
			<span className="collection-item-status__label">
				{label}
			</span>
		</div>
	);
}
