import { FooModel } from '@abb-emobility/oms/domain-model';
import {
	AccessibleUrlDto,
	DateRangeDto,
	UploadFileDto,
	ProtectedUrlDto,
	Dto,
	DownloadFileDto
} from '@abb-emobility/shared/domain-model-foundation';

const dateRange: DateRangeDto = {
	start: '2022-01-01T12:30:12.345Z',
	end: '2022-01-01T16:30:12.345Z'
};

const accessableUrl: AccessibleUrlDto = {
	accessible: true,
	value: 'https://fiege.com'
};

const protectedUrl: ProtectedUrlDto = {
	accessible: false,
	exchangeUrl: 'https://fiege.com'
};

const uploadFile: UploadFileDto = {
	dataUri: 'data:application/pdf;base64,JVBERi0xLjQKJeLjz9MKNSAwIG9iago8PAovRmlsdGVyIC9GbGF0ZURlY29kZQovTGVuZ3RoIDEyCj4+CnN0cmVhbQp4nCvkCuQCAAKSANcKZW5kc3RyZWFtCmVuZG9iago0IDAgb2JqCjw8Ci9UeXBlIC9QYWdlCi9NZWRpYUJveCBbMCAwIDc3MSAxMDczXQovUmVzb3VyY2VzIDw8Cj4+Ci9Db250ZW50cyA1IDAgUgovUGFyZW50IDIgMCBSCj4+CmVuZG9iagoyIDAgb2JqCjw8Ci9UeXBlIC9QYWdlcwovS2lkcyBbNCAwIFJdCi9Db3VudCAxCj4+CmVuZG9iagoxIDAgb2JqCjw8Ci9UeXBlIC9DYXRhbG9nCi9QYWdlcyAyIDAgUgo+PgplbmRvYmoKMyAwIG9iago8PAovQ3JlYXRpb25EYXRlIChEOjIwMjIwOTA3MTExMTAwWjAwJzAwJykKL1Byb2R1Y2VyIChpTG92ZVBERikKL01vZERhdGUgKEQ6MjAyMjA5MDcxMTEyMTBaKQo+PgplbmRvYmoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmDQowMDAwMDAwMjYwIDAwMDAwIG4NCjAwMDAwMDAyMDMgMDAwMDAgbg0KMDAwMDAwMDMwOSAwMDAwMCBuDQowMDAwMDAwMDk4IDAwMDAwIG4NCjAwMDAwMDAwMTUgMDAwMDAgbg0KdHJhaWxlcgo8PAovU2l6ZSA2Ci9Sb290IDEgMCBSCi9JbmZvIDMgMCBSCi9JRCBbPEM5MzZBNjI0NUI2Qjg0QzZDQzM0OUY5RTc2M0U0RUIzPiA8ODI2Q0M3REVCQjY4MTg5REIwOEYxRUZGREEzREMxMjk+XQo+PgpzdGFydHhyZWYKNDIwCiUlRU9GCg==',
	meta: {
		fileName: 'test.pdf',
		lastModifiedDate: '2022-01-01T16:30:12.345Z',
		size: 1024
	}
};

const downloadFile: DownloadFileDto = {
	url: {
		accessible: true,
		value: 'https://fiege.com'
	},
	meta: {
		mimeType: 'application/pdf',
		title: 'Test',
		fileName: 'test.pdf',
		lastModifiedDate: '2022-01-01T16:30:12.345Z',
		size: 12345
	}
};

export const fooModelMock: Dto<FooModel> = {
	id: '123',
	name: 'Foo 123',
	someCode: 'asd',
	dateOfBirth: '1977-02-22T16:30:12.345Z',
	dates: ['1977-02-22T16:30:12.345Z', '1980-02-22T16:30:12.345Z'],
	range: dateRange,
	url: accessableUrl,
	urls: [accessableUrl, protectedUrl],
	uploadFile: uploadFile,
	uploadFiles: [uploadFile, uploadFile, uploadFile],
	downloadFile: downloadFile,
	downloadFiles: [downloadFile, downloadFile]
};
