import React, { Children, ReactElement, ReactNode, useState, useTransition } from 'react';
import { useLocation } from 'react-router-dom';

import { Nullable, trimFromLeft } from '@abb-emobility/shared/util';

import { Tabbar } from '../tabbar/Tabbar';

import './TabbedPane.scss';

export type TabbedPaneProps<TabId extends string> = {
	children: ReactNode,
	activeTabId: TabId,
	renderTabContent: (tabId: TabId) => ReactNode,
	connectUrl?: boolean
};

export function TabbedPane<TabId extends string>(props: TabbedPaneProps<TabId>) {

	const { children, renderTabContent, connectUrl = false } = props;

	const location = useLocation();

	let initialActiveTabId = props.activeTabId;
	if (connectUrl) {
		const urlFragment = trimFromLeft(location.hash, '#');
		const urlTabId: Nullable<TabId> = urlFragment.length > 0 ? urlFragment as TabId : null;
		initialActiveTabId = urlTabId ?? initialActiveTabId;
	}
	const [activeTabId, setActiveTabId] = useState<TabId>(initialActiveTabId);
	const [_transitionPending, startTransition] = useTransition();

	const handleTabSelection = (selectedTabId: TabId): void => {
		startTransition(() => {
			setActiveTabId(selectedTabId);
		});
		if (connectUrl) {
			window.history.pushState({}, '', location.pathname + '#' + selectedTabId);
		}
	};

	const tabbarItemElements = Children.toArray(children) as Array<ReactElement>;

	const tabbarItems = tabbarItemElements.map((tabbarItemElement) => {
		return React.cloneElement(
			tabbarItemElement,
			{
				active: activeTabId === tabbarItemElement.props.tabId,
				onClick: () => handleTabSelection(tabbarItemElement.props.tabId)
			}
		);
	});

	const renderOptionalTabContent = (): ReactNode => {
		if (activeTabId === null) {
			return null;
		}
		return renderTabContent(activeTabId);
	};

	return (
		<article className="tabbed-pane">
			<header className="tabbed-pane__header">
				<Tabbar>
					{tabbarItems}
				</Tabbar>
			</header>
			<main className="tabbed-pane__main">
				{renderOptionalTabContent()}
			</main>
		</article>
	);

}
