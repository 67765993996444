import { ProgressState } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { TaskType } from '@abb-emobility/usertask/domain-model';

export type InstallationPartnerOrderProgressStepModel = {
	status: ProgressState,
	taskType: TaskType
};

export type InstallationPartnerOrderProgressModel = {
	steps: Array<InstallationPartnerOrderProgressStepModel>
};

export class InstallationPartnerOrderProgressModelConverter extends ModelConverter<InstallationPartnerOrderProgressModel> {
}
