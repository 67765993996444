import fetchMock from 'fetch-mock';

import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import {
	createMockBeginMatcher,
	createMockOptions,
	createMockResponse, preconfigureFetchMock
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { createDateRangeDtoFromDateRange, createDateRangeFromDates, DateRange, Dto } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { InstallerAppointmentApiClient } from './InstallerAppointmentApiClient';
import { installerAppointmentModelMock01 } from '../../mock-data/wallbox-installation/InstallerAppointmentModel.mock';

export class InstallerAppointmentApiClientMock extends InstallerAppointmentApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		const upcoming = (days: number, hours: number): Date => {
			const upcoming = new Date('2024-02-29T10:00:00Z');
			upcoming.setHours(upcoming.getHours() + hours);
			upcoming.setDate(upcoming.getDate() + days);
			return upcoming;
		};

		const dates: Array<DateRange> = [
			createDateRangeFromDates(upcoming(0, 0), upcoming(0, 2)),
			createDateRangeFromDates(upcoming(0, 2), upcoming(0, 4)),
			createDateRangeFromDates(upcoming(1, 0), upcoming(1, 2)),
			createDateRangeFromDates(upcoming(3, 0), upcoming(3, 2)),
			createDateRangeFromDates(upcoming(3, 2), upcoming(3, 4)),
			createDateRangeFromDates(upcoming(3, 4), upcoming(3, 6)),
			createDateRangeFromDates(upcoming(5, 0), upcoming(5, 2)),
			createDateRangeFromDates(upcoming(15, 0), upcoming(15, 2)),
			createDateRangeFromDates(upcoming(30, 0), upcoming(30, 2))
		];

		const appointments: Array<Dto<InstallerAppointmentModel>> = [];
		for (const i in dates) {
			const apiDateRange = createDateRangeDtoFromDateRange(dates[i]).getOrThrow(new Error('Creating API date range failed'));
			appointments.push(
				{
					...installerAppointmentModelMock01,
					id: i,
					period: apiDateRange,
					periodStartsAt: apiDateRange.start
				}
			);
		}

		fetchMock
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint(), true), createMockResponse(appointments), createMockOptions())
			.get('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(installerAppointmentModelMock01), createMockOptions())
			.patch('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(installerAppointmentModelMock01), createMockOptions())
			.post('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(installerAppointmentModelMock01), createMockOptions())
			.delete('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(installerAppointmentModelMock01), createMockOptions());
	}

}
