import { PersistedAnswersDto } from './Answers';

export const basicQuestionsQuestionnaireGermanyAnswersMock: PersistedAnswersDto = {
	'installation-location.property.type-of-house': 'single-family-house',
	'installation-location.property.construction-year': 'before-2000',
	'installation-location.property.ownership': 'self-owned-house',
	'installation-location.installation.location': 'garage-dry',
	'installation-location.installation.picture-location': {
		url: {
			accessible: false,
			exchangeUrl: '/resolve-protected-image/'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'installation-mounting-details-on-wall.installation.mounting':
		'on-insulated-wall',
	'installation-mounting-details-on-insulated-wall.insulation.thickness': 5,
	'masonry-works.wall-breakthrough.inner-wall-breakthroughs': 3,
	'masonry-works.wall-breakthrough.outer-wall-breakthroughs': 1,
	'electrical-installation.current-installation.overall-fuse': 36,
	'electrical-installation.current-installation.overvoltage-protection':
		'granted',
	'electrical-installation.current-installation.equipotential-bonding':
		'granted',
	'electrical-installation.current-installation.picture-distribution-box': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'electrical-installation.current-installation.picture-back-up-fuse': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'grid-operator.power-of-attorney.grid-operator': 'Stadtwerke Kiel',
	'grid-operator.power-of-attorney.calibration-regulations': 'granted',
	'grid-operator.power-of-attorney.power-of-attorney': 'granted'
};

export const basicQuestionsQuestionnaireAustriaAnswersMock: PersistedAnswersDto = {
	'installation-location.property.type-of-house': 'single-family-house',
	'installation-location.property.construction-year': 'before-2000',
	'installation-location.property.ownership': 'self-owned-house',
	'installation-location.property.house-connection': '50',
	'installation-location.installation.location': 'garage-dry',
	'installation-location.installation.picture-location': {
		url: {
			accessible: false,
			exchangeUrl: '/resolve-protected-image/'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'installation-mounting-details-on-wall.installation.mounting':
		'on-insulated-wall',
	'installation-mounting-details-on-insulated-wall.insulation.thickness': 5,
	'masonry-works.wall-breakthrough.inner-wall-breakthroughs': 3,
	'masonry-works.wall-breakthrough.outer-wall-breakthroughs': 1,
	'electrical-installation.current-installation.circuit-breaker': 'granted',
	'electrical-installation.current-installation.overvoltage-protection':
		'granted',
	'electrical-installation.current-installation.equipotential-bonding':
		'granted',
	'electrical-installation.current-installation.picture-distribution-box': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'electrical-installation.current-installation.empty-pipe': 'granted',
	'electrical-installation.current-installation.picture-back-up-fuse': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'grid-operator.power-of-attorney.grid-operator': 'Stadtwerke Kiel',
	'grid-operator.power-of-attorney.calibration-regulations': 'granted',
	'grid-operator.power-of-attorney.power-of-attorney': 'granted'
};

export const basicQuestionsQuestionnaireNetherlandsAnswersMock: PersistedAnswersDto = {
	'electrical-installation.electrical-installation.main-supply': 'three-phase-25A',
	'electrical-installation.electrical-installation.meter-box': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'electrical-installation.electrical-installation.meter-box-detail': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'installation-location.installation-location.wallbox-location': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'installation-location.installation-location.wallbox-mounting': 'wall',
	'installation-location.installation-location.basement-access': 'granted',
	'installation-location.installation-location.distance-meter-box-wallbox': '24',
	'installation-location.installation-location.earthworks-required': 'granted',
	'earthworks.earthworks.distance': '30',
	'earthworks.earthworks.flooring.tile': 'tile',
	'earthworks.earthworks.flooring.brick': 'brick',
	'earthworks.earthworks.earthworks-picture': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'installation-plan.installation-plan.installation-plan-picture': {
		url: {
			accessible: true,
			value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
		},
		meta: {
			title: 'Mitarbeiter Fiege',
			fileName: 'mitarbeiter-fiege.webp',
			mimeType: 'image/webp',
			size: 70,
			lastModifiedDate: '2022-01-01T16:30:12.345Z'
		}
	},
	'consent.power-of-attorney.grid-operator': 'SWN',
	'consent.power-of-attorney.power-of-attorney': 'granted',
	'consent.marketing-consent.installation-partner-marketing-consent': 'granted',
	'consent.survey-consent.survey-consent': 'granted'
};
