import React from 'react';

import './InformationBoxGroupItem.scss';

export type InformationBoxGroupItemProps = {
	title: string,
	content: string
};

export const InformationBoxGroupItem = (props: InformationBoxGroupItemProps) => {
	const { title, content } = props;

	return (
		<div className="information-box-group-item">
			<span className="information-box-group-item__title">{title}</span>
			<span className="information-box-group-item__content">{content}</span>
		</div>
	);
};
