import { ReactNode } from 'react';

import './InputRow.scss';

export type InputRowProps = {
	children: ReactNode
};

export function InputRow(props: InputRowProps) {

	const { children } = props;

	return (
		<div className="input-row">
			{children}
		</div>
	);
}
