import { MockRequest, MockResponse, MockResponseFunction } from 'fetch-mock';

import { ErrorDetailObject } from '@abb-emobility/shared/error';

import { JsonTransportValue } from '../json/JsonTransportValue';

export type ResponseHeader = {
	key: string,
	values: Array<string>
};

const createResponseHeaders = (headers: Array<ResponseHeader>): Headers => {
	const contentTypeHeader = headers.find((header) => {
		return header.key.toLowerCase() === 'content-type';
	});
	if (contentTypeHeader === undefined) {
		headers.push({
			key: 'Content-Type',
			values: ['application/json']
		});
	}

	const responseHeaders = new Headers();
	for (const header of headers) {
		responseHeaders.append(header.key, header.values.join(', '));
	}

	return responseHeaders;
};

export const createMockResponse = (
	responseData: JsonTransportValue,
	status = 200,
	headers: Array<ResponseHeader> = []
): MockResponse => {
	return new Response(JSON.stringify(responseData), {
		status: status,
		headers: createResponseHeaders(headers)
	});
};

export const createMockResponseFunction = (
	responseDataFn: (url: string, opts: MockRequest) => JsonTransportValue,
	status = 200,
	headers: Array<ResponseHeader> = []
): MockResponseFunction => {
	return (url: string, opts: MockRequest): MockResponse => {
		return new Response(JSON.stringify(responseDataFn(url, opts)), {
			status: status,
			headers: createResponseHeaders(headers)
		});
	};
};

export const createMockHeadResponse = (
	status = 200,
	headers: Array<ResponseHeader> = []
): MockResponse => {
	return {
		status: status,
		headers: createResponseHeaders(headers)
	};
};

export const createMockErrorResponse = (status: number, details?: Array<ErrorDetailObject>): MockResponse => {
	return {
		body: JSON.stringify(createErrorResponseBody(status, details)),
		status: status,
		headers: {
			'Content-Type': 'application/json'
		}
	};
};

export const createMockOptions = (delay?: number) => {
	delay = delay ?? parseInt(process.env['NX_API_MOCK_DEFAULT_DELAY'] ?? '700');
	return {
		delay
	};
};

const createErrorResponseBody = (status: number, details?: Array<ErrorDetailObject>): JsonTransportValue => {
	return {
		status: status,
		error: 'Something went wrong',
		timestamp: '2022-03-08T15:23:11.246Z',
		details: details ?? []
	};
};
