import { MockMatcherFunction } from 'fetch-mock';

export const createMockBeginMatcher = (urlPattern: string, validAuthRequired = false): MockMatcherFunction => {
	const urlPatternUrl = new URL(urlPattern);
	urlPatternUrl.searchParams.forEach((_, key) => {
		urlPatternUrl.searchParams.delete(key);
	});

	return (url, opts): boolean => {
		if (!url.startsWith(urlPatternUrl.toString())) {
			return false;
		}
		if (validAuthRequired) {
			const headers = opts.headers as HeadersInit;
			if (!checkAuthHeader(headers)) {
				return false;
			}
		}
		return true;
	};
};

export const createMockContainsMatcher = (pathPattern: string, validAuthRequired = false): MockMatcherFunction => {
	return (url, opts): boolean => {
		if (!url.includes(pathPattern)) {
			return false;
		}
		if (validAuthRequired) {
			const headers = opts.headers as HeadersInit;
			if (!checkAuthHeader(headers)) {
				return false;
			}
		}
		return true;
	};
};

export const createMockBeginAndContainsMatcher = (urlPattern: string, pathPattern: string, validAuthRequired = false): MockMatcherFunction => {
	return (url, opts): boolean => {
		if (!url.startsWith(urlPattern)) {
			return false;
		}
		if (!url.includes(pathPattern)) {
			return false;
		}
		if (validAuthRequired) {
			const headers = opts.headers as HeadersInit;
			if (!checkAuthHeader(headers)) {
				return false;
			}
		}
		return true;
	};
};

const checkAuthHeader = (headers: HeadersInit): boolean => {
	const authHeaderName = Object.keys(headers).find((headerName) => {
		return headerName.toLowerCase() === 'authorization';
	});
	// No auth header
	if (!authHeaderName) {
		return false;
	}
	const authHeaderValue = (headers as Record<string, string>)?.[authHeaderName] ?? null;
	// No auth header value
	if (authHeaderValue === null) {
		return false;
	}
	// Invalid auth header
	return String(authHeaderValue).startsWith('Bearer ');
};
