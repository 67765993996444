import { ReactNode } from 'react';

import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { LabelPosition } from '../../types/LabelPosition';

import './InputCheckboxGroup.scss';

export type InputCheckboxGroupProps = {
	label: string,
	labelPosition?: LabelPosition,
	children: ReactNode
};

export function InputCheckboxGroup(props: InputCheckboxGroupProps) {
	const { label, children, labelPosition = 'top' } = props;

	return (
		<div className={`input-checkbox-group ${buildCssClassNameFromEnumValue(labelPosition, 'input-checkbox-group')}`}>
			<div className="input-checkbox-group__label">{label}</div>
			<div className="input-checkbox-group__items">{children}</div>
		</div>
	);
}
