import React, { ButtonHTMLAttributes } from 'react';

import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';
import { Pill } from '../../pill/Pill';

import './UserbarNotifications.scss';

export type TopbarMobileNotificationsProps = {
	pillValue: string
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function UserbarNotifications(props: TopbarMobileNotificationsProps) {
	const { pillValue, ...rest } = props;
	return (
		<button className="userbar-notifications" {...rest}>
			<Icon name={IconIdentifier.BELL} />
			<Pill value={pillValue} />
		</button>
	);
}
