import { ModelConverter, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type InstallerModel = {
	id: ModelPrimaryKey,
	userName: string,
	name?: string,
	mailAddress?: string,
	phoneNumber?: string
};

export class InstallerModelConverter extends ModelConverter<InstallerModel> {
}
