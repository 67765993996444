import fetchMock from 'fetch-mock';

import { AnyInstallationPartnerOrderInformationModel } from '@abb-emobility/oms/domain-model';
import {
	createMockBeginMatcher,
	createMockErrorResponse,
	createMockOptions,
	createMockResponse, preconfigureFetchMock
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { AnyInstallationPartnerOrderInformationApiClient } from './AnyInstallationPartnerOrderInformationApiClient';
import { registerProtectedUrlResolverApiFetchMock } from '../../mock-data/common/ProtectedUrlResolverApiClientMock';
import {
	installationPartnerOrderInformationApprovalModelMock,
	installationPartnerOrderInformationBasicAnswersModelMock,
	installationPartnerOrderInformationGridOperatorDecisionModelMock,
	installationPartnerOrderInformationGridOperatorRegistrationModelMock,
	installationPartnerOrderInformationPrecheckModelMock,
	installationPartnerOrderInformationQuotationModelMock
} from '../../mock-data/wallbox-installation/installation-partner-order-information/AnyInstallationPartnerOrderInformationModel.mock';

export class AnyInstallationPartnerOrderInformationApiClientMock extends AnyInstallationPartnerOrderInformationApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock(baseUrl: string) {
		preconfigureFetchMock();

		const orderInformationModelMocks: Array<Dto<AnyInstallationPartnerOrderInformationModel>> = [
			installationPartnerOrderInformationBasicAnswersModelMock(),
			installationPartnerOrderInformationGridOperatorRegistrationModelMock,
			installationPartnerOrderInformationGridOperatorDecisionModelMock,
			installationPartnerOrderInformationPrecheckModelMock,
			installationPartnerOrderInformationQuotationModelMock,
			installationPartnerOrderInformationApprovalModelMock
		];

		for (const orderInformationModelMock of orderInformationModelMocks) {
			fetchMock
				.get(
					createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint(orderInformationModelMock.id), true),
					createMockResponse(orderInformationModelMock),
					createMockOptions()
				)
				.get(
					createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint(orderInformationModelMock.id)),
					createMockErrorResponse(401),
					createMockOptions()
				);
		}

		registerProtectedUrlResolverApiFetchMock(baseUrl);
	}

}
