import fetchMock from 'fetch-mock';

import {
	createMockBeginMatcher,
	createMockOptions,
	createMockResponse, preconfigureFetchMock
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { createDateRangeDtoFromDates } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { OrderInstallerAppointmentApiClient } from './OrderInstallerAppointmentApiClient';
import {
	installerAppointmentModelMock01,
	installerAppointmentModelMock02,
	installerAppointmentModelMock03
} from '../../mock-data/wallbox-installation/InstallerAppointmentModel.mock';

export class OrderInstallerAppointmentApiClientMock extends OrderInstallerAppointmentApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		const upcoming = (days: number, hours: number): Date => {
			const upcoming = new Date('2024-02-29T10:00:00Z');
			upcoming.setHours(upcoming.getHours() + hours);
			upcoming.setDate(upcoming.getDate() + days);
			return upcoming;
		};

		const appointments = [
			{
				...installerAppointmentModelMock01,
				id: '1',
				period: createDateRangeDtoFromDates(upcoming(0, 0), upcoming(0, 2)).getOrThrow(new Error('Creating API date range failed'))
			},
			{
				...installerAppointmentModelMock02,
				id: '2',
				period: createDateRangeDtoFromDates(upcoming(0, 2), upcoming(0, 4)).getOrThrow(new Error('Creating API date range failed'))
			},
			{
				...installerAppointmentModelMock03,
				id: '3',
				period: createDateRangeDtoFromDates(upcoming(1, 0), upcoming(1, 2)).getOrThrow(new Error('Creating API date range failed'))
			},
			{
				...installerAppointmentModelMock01,
				id: '4',
				period: createDateRangeDtoFromDates(upcoming(3, 0), upcoming(1, 2)).getOrThrow(new Error('Creating API date range failed'))
			},
			{
				...installerAppointmentModelMock01,
				id: '5',
				period: createDateRangeDtoFromDates(upcoming(5, 0), upcoming(1, 2)).getOrThrow(new Error('Creating API date range failed'))
			},
			{
				...installerAppointmentModelMock01,
				id: '6',
				period: createDateRangeDtoFromDates(upcoming(9, 0), upcoming(9, 2)).getOrThrow(new Error('Creating API date range failed'))
			},
			{
				...installerAppointmentModelMock01,
				id: '7',
				period: createDateRangeDtoFromDates(upcoming(15, 0), upcoming(15, 2)).getOrThrow(new Error('Creating API date range failed'))
			}
		];

		fetchMock
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint(), true), createMockResponse(appointments), createMockOptions())
			.get('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(installerAppointmentModelMock01), createMockOptions())
			.patch('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(installerAppointmentModelMock01), createMockOptions())
			.post('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(installerAppointmentModelMock01), createMockOptions())
			.delete('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(installerAppointmentModelMock01), createMockOptions());
	}

}
