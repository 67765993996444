import { buildCssClassStringFromClassMap, extendCssMapByEnumValue } from '@abb-emobility/shared/util';

import { ItemWidth } from '../CollectionHead.enums';

import './CollectionHeadItem.scss';

export type CollectionHeadItemProps = {
	label?: string
	itemWidth?: ItemWidth
};

export function CollectionHeadItem(props: CollectionHeadItemProps) {
	const { label, itemWidth = ItemWidth.ITEM_WIDTH_MEDIUM } = props;

	const cssClassMap = {
		'collection-head-item': true
	};
	extendCssMapByEnumValue(cssClassMap, itemWidth, 'collection-head-item');

	return (
		<div className={buildCssClassStringFromClassMap(cssClassMap)}>
			{label}
		</div>
	);
}
