import { CustomerOrderModel, CustomerOrderState } from '@abb-emobility/oms/domain-model';
import { marketplaceModelMock } from '@abb-emobility/shared/api-integration';
import { createTimestampDtoFromDate, Dto } from '@abb-emobility/shared/domain-model-foundation';

import { customerOrderProgressMock } from './CustomerOrderProgressModel.mock';

export const customerOrderModelMock = (): Dto<CustomerOrderModel> => {
	return {
		id: '1',
		marketplaceOrderId: '123456',
		orderDate: createTimestampDtoFromDate(new Date('2023-12-15T10:00:00Z')),
		orderState: CustomerOrderState.RUNNING,
		progress: customerOrderProgressMock(),
		marketplace: marketplaceModelMock
	};
};
