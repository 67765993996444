import React from 'react';

import './AppLayoutMobileTopbarSticky.scss';

import { AppLayoutNavigationProps } from '../../app-layout/app-layout-navigation/AppLayoutNavigation';

export function AppLayoutMobileTopbarSticky(props: AppLayoutNavigationProps) {
	const { children } = props;

	return (
		<section className="app-layout-mobile-topbar-sticky">
			{children}
		</section>
	);
}
