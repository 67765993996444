import { Model, ModelValue } from '@abb-emobility/shared/domain-model-foundation';

export type ModelSort<Model> = (left: Model, right: Model) => number;

export enum ModelSortDirection {
	ASC = 'ASC',
	DESC = 'DESC'
}

export enum ModelSortComparator {
	NUMERIC,
	ALPHANUMERIC_CS,
	ALPHANUMERIC_CI
}

export const createKeySort = <T extends Model>(
	key: keyof T,
	direction: ModelSortDirection,
	comparator: ModelSortComparator = ModelSortComparator.ALPHANUMERIC_CI
): ModelSort<T> => {
	const returnLeft = direction === ModelSortDirection.ASC ? -1 : 1;
	const returnRight = direction === ModelSortDirection.ASC ? 1 : -1;
	return (left: T, right: T) => {
		let leftValue: ModelValue = left[key];
		let rightValue: ModelValue = right[key];
		if (
			(typeof leftValue !== 'number' && typeof leftValue !== 'boolean' && typeof leftValue !== 'string')
			|| (typeof rightValue !== 'number' && typeof rightValue !== 'boolean' && typeof rightValue !== 'string')
		) {
			throw new Error('Invalid sort value for key ' + key.toString() + '. Expected type string, number or boolean');
		}

		switch (comparator) {
			case ModelSortComparator.ALPHANUMERIC_CS:
				leftValue = String(leftValue);
				rightValue = String(rightValue);
				break;
			case ModelSortComparator.ALPHANUMERIC_CI:
				leftValue = String(leftValue).toLowerCase();
				rightValue = String(rightValue).toLowerCase();
				break;
			case ModelSortComparator.NUMERIC:
				leftValue = parseFloat(leftValue as string);
				rightValue = parseFloat(rightValue as string);
				break;
		}
		if (leftValue === rightValue) {
			return 0;
		}
		return leftValue < rightValue ? returnLeft : returnRight;
	};
};
