export * from './lib/QuestionnaireAnswers';

export { default as sharedUiQuestionnaireLiteralsDe } from './l10n/literals-de.json';
export { default as sharedUiQuestionnaireLiteralsEn } from './l10n/literals-en.json';
export { default as sharedUiQuestionnaireLiteralsEs } from './l10n/literals-es.json';
export { default as sharedUiQuestionnaireLiteralsFr } from './l10n/literals-fr.json';
export { default as sharedUiQuestionnaireLiteralsIt } from './l10n/literals-it.json';
export { default as sharedUiQuestionnaireLiteralsNl } from './l10n/literals-nl.json';
export { default as sharedUiQuestionnaireLiteralsPt } from './l10n/literals-pt.json';
export { default as sharedUiQuestionnaireLiteralsSv } from './l10n/literals-sv.json';
