import { ModelConverter, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';

export type OrderImportTaxMatrixModel = {
	b2c: {
		goods: number,
		goodsAndServices: number
	},
	b2b: {
		goods: number,
		goodsAndServices: number
	}
};

export type OrderImportInstallationAddressModel = {
	readonly id: ModelPrimaryKey,
	readonly street: string,
	readonly city: string,
	readonly postalCode: string,
	readonly region: string,
	readonly country: IsoCountryCode,
	readonly taxMatrix: OrderImportTaxMatrixModel
};

export class OrderImportInstallationAddressModelConverter extends ModelConverter<OrderImportInstallationAddressModel> {
}
