import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

type GenericContextValue = {
	set: <T>(value: T) => void,
	get: <T>() => Nullable<T>
};

const genericContext = createContext<GenericContextValue>({
	set: (): void => {
		throw new Error('No context provided');
	},
	get: <T, >(): Nullable<T> => {
		throw new Error('No context provided');
	}
});

export type GenericContextProviderProps = {
	initialValue?: unknown,
	children: ReactNode
};

export const GenericContextProvider = (props: GenericContextProviderProps): React.JSX.Element => {

	const { initialValue = null, children } = props;

	const [currentValue, setCurrentValue] = useState<Nullable<unknown>>(initialValue);

	const providerValue = {
		set: <T, >(value: T) => {
			setCurrentValue(value);
		},
		get: <T, >(): Nullable<T> => {
			return currentValue as T;
		}
	};

	return (
		<genericContext.Provider value={providerValue}>
			{children}
		</genericContext.Provider>
	);

};

export const useGenericContext = () => {
	return useContext(genericContext);
};
