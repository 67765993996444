import {
	ArrayOfTimestampFieldNames,
	ModelConverter,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

export type ReturnNoteModel = {
	readonly creationDate: Timestamp,
	readonly link: string
};

export class ReturnNoteModelConverter extends ModelConverter<ReturnNoteModel> {

	override getTimestampFields(): Array<TimestampFieldNames<ReturnNoteModel> | ArrayOfTimestampFieldNames<ReturnNoteModel>> {
		return ['creationDate'];
	}
}
