import { HttpMethod } from '../network/HttpMethod';
import { JsonRestRequestFactory } from '../network/JsonRestRequestFactory';

export type CorsSettings = {
	allowedHeaders: Array<string>,
	allowedMethods: Array<HttpMethod>
};

export const corsPreflight = async (apiUrl: string): Promise<CorsSettings> => {

	const request = JsonRestRequestFactory.create();
	const response = await request.options(apiUrl);
	console.table(Object.fromEntries(response.headers));

	return {
		allowedHeaders: response.headers
			.get('access-control-allow-headers')
			?.split(',')
			.map((headerValue) => {
				return headerValue.trim().toLowerCase();
			}) ?? [],
		allowedMethods: response.headers
			.get('access-control-allow-methods')
			?.split(',')
			.map((headerValue) => {
				return headerValue.trim().toUpperCase();
			})
			.filter((headerValue): headerValue is HttpMethod => {
				return (Object.values(HttpMethod) as Array<string>).includes(headerValue);
			}) ?? []
	};

};
