import React from 'react';

import { AppLayoutMainContentProps } from '../../../app-layout/app-layout-main/app-layout-main-content/AppLayoutMainContent';

import './AppLayoutMobileMainContent.scss';

export function AppLayoutMobileMainContent(props: AppLayoutMainContentProps) {

	const { children } = props;

	return (
		<main className="app-layout-mobile-main-content">
			{children}
		</main>
	);
}
