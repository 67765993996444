import { init, BrowserOptions, captureException, captureMessage, User, addBreadcrumb } from '@sentry/react';

import { EventType, Logger, LogLevel } from '@abb-emobility/shared/logger';
import { EventData } from '@abb-emobility/shared/logger';
import { Maybe, Nullable } from '@abb-emobility/shared/util';

enum SentryLogLevel {
	FATAL = 'fatal',
	ERROR = 'error',
	WARNING = 'warning',
	LOG = 'log',
	INFO = 'info',
	DEBUG = 'debug'
}

enum SentryEventLevel {
	FATAL = 'fatal',
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info',
	DEBUG = 'debug'
}

enum SentryEventType {
	DEBUG = 'debug',
	ERROR = 'error',
	NAVIGATION = 'navigation',
	HTTP = 'http',
	INFO = 'info',
	QUERY = 'query',
	UI = 'ui',
	USER = 'user'
}

export type TagValue = number | string | boolean | bigint | symbol | null | undefined;

export class SentryLogger implements Logger {

	private lastError: Nullable<Error> = null;
	private lastMessage: unknown = null;

	private readonly doubleLogProtection: boolean;
	private readonly filterError: Nullable<(error: Error, level: LogLevel) => boolean>;
	private readonly filterMessage: Nullable<(message: unknown, level: LogLevel) => boolean>;
	private readonly enrichUser: Nullable<() => Maybe<string>>;
	private readonly enrichTags: Nullable<() => Maybe<Map<string, TagValue>>>;

	constructor(
		sentryOptions: BrowserOptions,
		doubleLogProtection?: boolean,
		filterError?: (error: Error, level: LogLevel) => boolean,
		filterMessage?: (message: unknown, level: LogLevel) => boolean,
		enrichUser?: () => Maybe<string>,
		enrichTags?: () => Maybe<Map<string, TagValue>>
	) {
		init(sentryOptions);
		this.doubleLogProtection = doubleLogProtection ?? false;
		this.filterError = filterError ?? null;
		this.filterMessage = filterMessage ?? null;
		this.enrichUser = enrichUser ?? null;
		this.enrichTags = enrichTags ?? null;
	}

	public async logEvent(message: string, data?: EventData, category?: string, level: LogLevel = LogLevel.INFO): Promise<void> {
		addBreadcrumb({
			type: this.deriveEventType(data?.type),
			data: data?.payload,
			category,
			message,
			level: this.deriveEventLevel(level)
		});
	}

	public async logError(error: Error, level: LogLevel = LogLevel.LOG): Promise<void> {
		if (this.doubleLogProtection && this.lastError === error) {
			return;
		}
		if (this.filterError === null || this.filterError(error, level)) {
			this.lastError = error;
			captureException(error, {
				user: this.deriveUser(),
				level: this.deriveLogLevel(level),
				tags: this.deriveTags()
			});
		}
	}

	public async logMessage(message: unknown, level: LogLevel = LogLevel.LOG): Promise<void> {
		if (this.doubleLogProtection && this.lastMessage === message) {
			return;
		}
		if (this.filterMessage === null || this.filterMessage(message, level)) {
			this.lastMessage = message;
			captureMessage(this.stringifyMessage(message), {
				user: this.deriveUser(),
				level: this.deriveLogLevel(level),
				tags: this.deriveTags()
			});
		}
	}

	private deriveUser(): Maybe<User> {
		if (this.enrichUser === null) {
			return undefined;
		}
		const user = this.enrichUser();
		if (!user) {
			return undefined;
		}
		return { id: user };
	}

	private deriveTags(): Maybe<Record<string, TagValue>> {
		if (this.enrichTags === null) {
			return undefined;
		}
		const tags = this.enrichTags();
		if (!tags) {
			return undefined;
		}
		const sentryTags: Record<string, TagValue> = {};
		tags.forEach((value, key) => {
			sentryTags[key] = value;
		});
		return sentryTags;
	}

	private deriveLogLevel(level: LogLevel): SentryLogLevel {
		switch (level) {
			case LogLevel.DEBUG:
				return SentryLogLevel.DEBUG;
			case LogLevel.INFO:
				return SentryLogLevel.INFO;
			case LogLevel.LOG:
				return SentryLogLevel.LOG;
			case LogLevel.WARN:
				return SentryLogLevel.WARNING;
			case LogLevel.ERROR:
				return SentryLogLevel.ERROR;
			case LogLevel.FATAL:
				return SentryLogLevel.FATAL;
		}
	}

	private deriveEventType(type?: EventType): Maybe<SentryEventType> {
		if (!type) {
			return undefined;
		}
		switch (type) {
			case EventType.DEBUG:
				return SentryEventType.DEBUG;
			case EventType.ERROR:
				return SentryEventType.ERROR;
			case EventType.NAVIGATION:
				return SentryEventType.NAVIGATION;
			case EventType.HTTP:
				return SentryEventType.HTTP;
			case EventType.INFO:
				return SentryEventType.INFO;
			case EventType.QUERY:
				return SentryEventType.QUERY;
			case EventType.UI:
				return SentryEventType.UI;
			case EventType.USER:
				return SentryEventType.USER;
		}
	}

	private deriveEventLevel(level: LogLevel): SentryEventLevel {
		switch (level) {
			case LogLevel.DEBUG:
				return SentryEventLevel.DEBUG;
			case LogLevel.INFO:
			case LogLevel.LOG:
				return SentryEventLevel.INFO;
			case LogLevel.WARN:
				return SentryEventLevel.WARNING;
			case LogLevel.ERROR:
				return SentryEventLevel.ERROR;
			case LogLevel.FATAL:
				return SentryEventLevel.FATAL;
		}
	}

	private stringifyMessage(message: unknown): string {
		switch (typeof message) {
			case 'string':
				return message;
			case 'object':
				return JSON.stringify(message);
			default:
				return String(message);
		}
	}

}
