import { AnyInstallationPartnerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	installationPartnerOrderInformationApprovalModelMock,
	installationPartnerOrderInformationBasicAnswersModelMock,
	installationPartnerOrderInformationGridOperatorDecisionModelMock,
	installationPartnerOrderInformationGridOperatorRegistrationModelMock,
	installationPartnerOrderInformationPrecheckModelMock,
	installationPartnerOrderInformationQuotationModelMock
} from './AnyInstallationPartnerOrderInformationModel.mock';

export const anyInstallationPartnerOrderInformationCollectionItemModelMock = (): Array<Dto<AnyInstallationPartnerOrderInformationCollectionItemModel>> => {
	const installationPartnerOrderInformationBasicAnswersModelLocalizedMock = installationPartnerOrderInformationBasicAnswersModelMock();
	return [
		{
			id: installationPartnerOrderInformationBasicAnswersModelLocalizedMock.id,
			orderId: installationPartnerOrderInformationBasicAnswersModelLocalizedMock.orderId,
			type: installationPartnerOrderInformationBasicAnswersModelLocalizedMock.type,
			createdAt: installationPartnerOrderInformationBasicAnswersModelLocalizedMock.createdAt
		},
		{
			id: installationPartnerOrderInformationGridOperatorRegistrationModelMock.id,
			orderId: installationPartnerOrderInformationGridOperatorRegistrationModelMock.orderId,
			type: installationPartnerOrderInformationGridOperatorRegistrationModelMock.type,
			createdAt: installationPartnerOrderInformationGridOperatorRegistrationModelMock.createdAt
		},
		{
			id: installationPartnerOrderInformationGridOperatorDecisionModelMock.id,
			orderId: installationPartnerOrderInformationGridOperatorDecisionModelMock.orderId,
			type: installationPartnerOrderInformationGridOperatorDecisionModelMock.type,
			createdAt: installationPartnerOrderInformationGridOperatorDecisionModelMock.createdAt
		},
		{
			id: installationPartnerOrderInformationPrecheckModelMock.id,
			orderId: installationPartnerOrderInformationPrecheckModelMock.orderId,
			type: installationPartnerOrderInformationPrecheckModelMock.type,
			createdAt: installationPartnerOrderInformationPrecheckModelMock.createdAt
		},
		{
			id: installationPartnerOrderInformationQuotationModelMock.id,
			orderId: installationPartnerOrderInformationQuotationModelMock.orderId,
			type: installationPartnerOrderInformationQuotationModelMock.type,
			createdAt: installationPartnerOrderInformationQuotationModelMock.createdAt
		},
		{
			id: installationPartnerOrderInformationApprovalModelMock.id,
			orderId: installationPartnerOrderInformationApprovalModelMock.orderId,
			type: installationPartnerOrderInformationApprovalModelMock.type,
			createdAt: installationPartnerOrderInformationApprovalModelMock.createdAt
		}
	];
};
