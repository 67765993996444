/* eslint-disable @typescript-eslint/naming-convention */
import { jwtDecode, JwtPayload } from 'jwt-decode';

import { AuthenticationFailedError, TimeoutError } from '@abb-emobility/shared/error';
import { Timeout } from '@abb-emobility/shared/util';

type TokenRequestResponseModel = {
	id_token: string,
	access_token: string,
	expires_in: number,
	exp: number
};

const buildRealmUrl = (baseUrl: string, realm: string): string => {
	return baseUrl + 'auth/realms/' + realm;
};

export const keycloakRequestTokenBySecret = async (
	baseUrl: string,
	realm: string,
	clientId: string,
	clientSecret: string,
	additionalHeaders?: Record<string, string>
): Promise<TokenRequestResponseModel> => {
	const abortController = new AbortController();

	const headers = {
		...additionalHeaders ?? {},
		'Content-Type': 'application/x-www-form-urlencoded'
	};

	const request = new Request(
		buildRealmUrl(baseUrl, realm) + '/protocol/openid-connect/token',
		{
			signal: abortController.signal,
			method: 'POST',
			cache: 'no-cache',
			headers: headers,
			body: new URLSearchParams({
				grant_type: 'client_credentials',
				client_id: clientId ?? '',
				client_secret: clientSecret ?? '',
				scope: 'openid'
			}).toString()
		}
	);

	const response = await Timeout.wrap<Response>(fetch(request), 5000, new TimeoutError('Request timeout'), (): void => {
		abortController.abort();
	});
	if (!response.ok) {
		throw new AuthenticationFailedError('Token request failed');
	}

	let responseBody: TokenRequestResponseModel;
	try {
		responseBody = await response.json();
	} catch (e) {
		throw new AuthenticationFailedError('Token response invalid');
	}

	let decodedToken;
	try {
		decodedToken = jwtDecode<JwtPayload>(responseBody.access_token);
	} catch (e) {
		console.warn(e);
		decodedToken = {};
	}

	return {
		...responseBody,
		...decodedToken
	};
};
