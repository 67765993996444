import fetchMock from 'fetch-mock';

import {
	createMockBeginMatcher,
	createMockOptions,
	createMockResponse, preconfigureFetchMock
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { AnyInstallationPartnerOrderInformationCollectionApiClient } from './AnyInstallationPartnerOrderInformationCollectionApiClient';
import {
	anyInstallationPartnerOrderInformationCollectionItemModelMock
} from '../../mock-data/wallbox-installation/installation-partner-order-information/AnyInstallationPartnerOrderInformationCollectionItemModel.mock';

export class AnyInstallationPartnerOrderInformationCollectionApiClientMock extends AnyInstallationPartnerOrderInformationCollectionApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		fetchMock
			.get(createMockBeginMatcher(this.getUrlBuilder().buildPaginationEndpoint(1)), createMockResponse(anyInstallationPartnerOrderInformationCollectionItemModelMock(), 200, [
				{ key: 'X-Pagination-Page', values: ['1'] },
				{ key: 'X-Pagination-Max-Pages', values: ['3'] },
				{ key: 'X-Pagination-Total-Items', values: ['6'] }
			]), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildPaginationEndpoint(2)), createMockResponse(anyInstallationPartnerOrderInformationCollectionItemModelMock(), 200, [
				{ key: 'X-Pagination-Page', values: ['2'] },
				{ key: 'X-Pagination-Max-Pages', values: ['3'] },
				{ key: 'X-Pagination-Total-Items', values: ['6'] }
			]), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildPaginationEndpoint(3)), createMockResponse(anyInstallationPartnerOrderInformationCollectionItemModelMock(), 200, [
				{ key: 'X-Pagination-Page', values: ['3'] },
				{ key: 'X-Pagination-Max-Pages', values: ['3'] },
				{ key: 'X-Pagination-Total-Items', values: ['6'] }
			]), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint()), createMockResponse(anyInstallationPartnerOrderInformationCollectionItemModelMock(), 200, [
				{ key: 'X-Pagination-Page', values: ['1'] },
				{ key: 'X-Pagination-Max-Pages', values: ['3'] },
				{ key: 'X-Pagination-Total-Items', values: ['6'] }
			]), createMockOptions());
	}

}
