import { metrics, BrowserOptions, reactRouterV6BrowserTracingIntegration, browserProfilingIntegration } from '@sentry/react';
import { Integration } from '@sentry/types/types/integration';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { CustomError } from 'ts-custom-error';

import { SimpleError } from '@abb-emobility/shared/error';

export const sentryConfig = (): BrowserOptions => {

	const integrations: Array<Integration> = [
		reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
		metrics.metricsAggregatorIntegration()
	];

	if (process.env['NX_SENTRY_PROFILING_ENABLED'] === 'true') {
		integrations.push(browserProfilingIntegration());
	}

	return {
		dsn: process.env['NX_SENTRY_DSN'],
		integrations,
		debug: process.env['NX_SENTRY_DEBUG'] === 'true',
		environment: process.env?.['NX_SENTRY_ENVIRONMENT'] ?? undefined,
		// Capture 100% of the transactions
		tracesSampleRate: 1.0,
		tracePropagationTargets: [/^\//, /^localhost/, /^(https:\/\/)api\.?\w*\.wallbox\.evcharging\.abb\.com\/frontend-notification/],
		// Capture 100% of the profiled transactions (effective value is tracesSampleRate * profilesSampleRate)
		profilesSampleRate: 1.0,
		// Filter out console output from breadcrumbs
		beforeBreadcrumb: (breadcrumb, _hint) => {
			return breadcrumb.category === 'console' ? null : breadcrumb;
		},
		// Error fingerprinting to support Sentry issue grouping
		beforeSend: function (event, hint) {
			const exception = hint.originalException;
			if (exception instanceof SimpleError) {
				event.fingerprint = [
					'{{ default }}',
					exception.name,
					String(exception.status)
				];
			} else if (exception instanceof CustomError) {
				event.fingerprint = [
					'{{ default }}',
					exception.name
				];
			}
			return event;
		}
	};

};
