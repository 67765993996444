import fetchMock from 'fetch-mock';

import {
	createMockBeginMatcher,
	createMockErrorResponse,
	createMockOptions,
	createMockResponse, preconfigureFetchMock
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { OrderImportB2cApiClient } from './OrderImportB2cApiClient';

export class OrderImportB2cApiClientMock extends OrderImportB2cApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		fetchMock
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint()), createMockErrorResponse(400), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCreateEntityEndpoint(), true), createMockErrorResponse(400), createMockOptions())
			.put(createMockBeginMatcher(this.getUrlBuilder().buildCreateEntityEndpoint(), true), createMockErrorResponse(400), createMockOptions())
			.patch(createMockBeginMatcher(this.getUrlBuilder().buildCreateEntityEndpoint(), true), createMockErrorResponse(400), createMockOptions())
			.delete(createMockBeginMatcher(this.getUrlBuilder().buildCreateEntityEndpoint(), true), createMockErrorResponse(400), createMockOptions())
			.post(createMockBeginMatcher(this.getUrlBuilder().buildCreateEntityEndpoint(), true), createMockResponse({}, 202), createMockOptions());
	}

}
