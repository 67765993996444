import fetchMock from 'fetch-mock';

import { CustomerOrderDocumentModel } from '@abb-emobility/oms/domain-model';
import {
	createMockOptions,
	createMockResponse,
	CrudApiClient,
	preconfigureFetchMock
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { FooApiClient } from './FooApiClient';
import { fooModelMock } from '../../mock-data/foo/FooModel.mock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FooApiClientInterface extends CrudApiClient<CustomerOrderDocumentModel> {
}

export class FooApiClientMock extends FooApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		fetchMock
			.get(this.getUrlBuilder().buildCollectionEndpoint(), createMockResponse([fooModelMock, fooModelMock]), createMockOptions())
			.get('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(fooModelMock), createMockOptions())
			.get('begin:' + this.getUrlBuilder().buildSearchEntityEndpoint([]), createMockResponse(fooModelMock), createMockOptions())
			.put('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(fooModelMock), createMockOptions())
			.patch('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(fooModelMock), createMockOptions())
			.post('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(fooModelMock), createMockOptions())
			.delete('begin:' + this.getUrlBuilder().buildCreateEntityEndpoint(), createMockResponse(fooModelMock), createMockOptions());
	}

}
