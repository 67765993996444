import { CustomerOrderProgressModel } from '@abb-emobility/oms/domain-model';
import { ProgressState } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';
import { TaskType } from '@abb-emobility/usertask/domain-model';

export const customerOrderProgressMock = (): Dto<CustomerOrderProgressModel> => {
	return {
		steps: [
			{
				current: false,
				taskId: 'c1',
				taskType: TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS,
				status: ProgressState.DONE
			},
			{
				current: true,
				taskId: 'c2',
				taskType: TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES,
				status: ProgressState.ONGOING
			},
			{
				current: false,
				taskId: 'c4',
				taskType: TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION,
				status: ProgressState.PENDING
			},
			{
				current: false,
				taskId: 'c3',
				taskType: TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION,
				status: ProgressState.PENDING
			},
			{
				current: false,
				taskId: 'c5',
				taskType: TaskType.CUSTOMER_ACCEPT_QUOTATION,
				status: ProgressState.PENDING
			},
			{
				current: false,
				taskId: 'c7',
				taskType: TaskType.CUSTOMER_APPROVE_INSTALLATION,
				status: ProgressState.PENDING
			}
		]
	};
};
