import fetchMock from 'fetch-mock';

import {
	createMockBeginMatcher,
	createMockOptions,
	createMockResponse,
	preconfigureFetchMock
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerOrderApiClient } from './CustomerOrderApiClient';
import { customerOrderModelMock } from '../../mock-data/customer/CustomerOrderModel.mock';

export class CustomerOrderApiClientMock extends CustomerOrderApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		fetchMock
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint()), createMockResponse([
				customerOrderModelMock(),
				customerOrderModelMock(),
				customerOrderModelMock(),
				customerOrderModelMock()
			], 200, [
				{ key: 'X-Pagination-Page', values: ['1'] },
				{ key: 'X-Pagination-Max-Pages', values: ['3'] },
				{ key: 'X-Pagination-Total-Items', values: ['4'] }
			]), createMockOptions())
			.get(
				createMockBeginMatcher(this.getUrlBuilder().buildCreateEntityEndpoint(), true),
				createMockResponse(customerOrderModelMock()),
				createMockOptions()
			);
	}

}
