import fetchMock from 'fetch-mock';

import {
	createMockBeginMatcher, createMockErrorResponse,
	createMockHeadResponse,
	createMockOptions,
	createMockResponse, preconfigureFetchMock,
	ResponseHeader
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { InstallationPartnerOrderApiClient } from './InstallationPartnerOrderApiClient';
import { installationPartnerOrderModelMock } from '../../mock-data/wallbox-installation/InstallationPartnerOrderModel.mock';

export class InstallationPartnerOrderApiClientMock extends InstallationPartnerOrderApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		const createEntityCommands = (id: ModelPrimaryKey): Array<ResponseHeader> => {
			return [
				{
					key: 'Link',
					values: ['<' + encodeURI(this.getUrlBuilder().buildEntityMethodEndpoint(id, 'add-note', true)) + '>; rel="ADD_NOTE"']
				},
				{
					key: 'Link',
					values: ['<' + encodeURI(this.getUrlBuilder().buildEntityMethodEndpoint(id, 'cancel-installation', true)) + '>; rel="CANCEL_INSTALLATION"']
				},
				{
					key: 'Link',
					values: ['<' + encodeURI(this.getUrlBuilder().buildEntityMethodEndpoint(id, 'cancel-order', true)) + '>; rel="CANCEL_ORDER"']
				},
				{
					key: 'Link',
					values: ['<' + encodeURI(this.getUrlBuilder().buildEntityMethodEndpoint(id, 'cancel-order', true)) + '>; rel="CANCEL_ORDER"']
				},
				{
					key: 'Link',
					values: ['<' + encodeURI(this.getUrlBuilder().buildEntityMethodEndpoint(id, 'cancel-order-appointments', true)) + '>; rel="CANCEL_ORDER_APPOINTMENTS"']
				}
			];
		};

		// Collection mock
		fetchMock
			.get(
				createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint(), true), createMockResponse([
					installationPartnerOrderModelMock('1'),
					installationPartnerOrderModelMock('2'),
					installationPartnerOrderModelMock('3'),
					installationPartnerOrderModelMock('4')
				], 200, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['4'] }
				]), createMockOptions()
			)
			.head(
				createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint(), true), createMockHeadResponse(200, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['4'] }
				]), createMockOptions()
			);

		// Entity action mock
		for (const id of ['1', '2', '3', '4', '5', '6', '9']) {
			fetchMock
				.get(
					'begin:' + this.getUrlBuilder().buildEntityMethodEndpoint(id, 'cancel-order-appointments'),
					createMockResponse({}),
					createMockOptions()
				)
				.put(
					'begin:' + this.getUrlBuilder().buildEntityMethodEndpoint(id, 'cancel-order-appointments'),
					createMockResponse({}),
					createMockOptions()
				);
		}

		// Entity mock
		fetchMock
			.get(createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint('1'), true), createMockResponse(installationPartnerOrderModelMock('1'), 200, createEntityCommands('1')), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint('2'), true), createMockResponse(installationPartnerOrderModelMock('2'), 200, createEntityCommands('2')), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint('3'), true), createMockResponse(installationPartnerOrderModelMock('3'), 200, createEntityCommands('3')), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint('4'), true), createMockResponse(installationPartnerOrderModelMock('4'), 200, createEntityCommands('4')), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint('5'), true), createMockResponse(installationPartnerOrderModelMock('5'), 200, createEntityCommands('5')), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint('6'), true), createMockResponse(installationPartnerOrderModelMock('6'), 200, createEntityCommands('6')), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint('9'), true), createMockResponse(installationPartnerOrderModelMock('9'), 200, createEntityCommands('9')), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCreateEntityEndpoint(), true), createMockErrorResponse(404), createMockOptions());
	}

}
