import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

export type OrderImportProductBundleModel = {
	readonly id: string,
	readonly name: string,
	readonly price: number,
	readonly includesServices: boolean
};

export class OrderImportProductBundleModelConverter extends ModelConverter<OrderImportProductBundleModel> {
}
