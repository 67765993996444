import React, { ForwardedRef, ReactNode } from 'react';

import { buildCssClassStringFromClassMap, extendCssMapByEnumValue } from '@abb-emobility/shared/util';

import { ItemWidth } from '../../../collection-head/CollectionHead.enums';

import './CollectionItemHeaderItem.scss';

export type CollectionItemHeaderItemProps = {
	allowOverflow?: boolean,
	itemWidth?: ItemWidth,
	children: ReactNode
};

export const CollectionItemHeaderItem = React.forwardRef((props: CollectionItemHeaderItemProps, ref: ForwardedRef<HTMLDivElement>) => {
	const { allowOverflow = false, itemWidth = ItemWidth.ITEM_WIDTH_MEDIUM, children } = props;

	const cssClassMap = {
		'collection-item-header-item': true,
		'collection-item-header-item--overflow': allowOverflow
	};

	extendCssMapByEnumValue(cssClassMap, itemWidth, 'collection-item-header-item');

	return (
		<div className={buildCssClassStringFromClassMap(cssClassMap)} ref={ref}>
			{children}
		</div>
	);
});
