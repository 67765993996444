import { MarketplaceModel } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export const marketplaceModelMock: Dto<MarketplaceModel> = {
	id: '1',
	name: 'Smart Marketplace Germany',
	customerServiceHotline: '+49 711 5507 7020',
	customerServiceMailAddress: 'hallo@smart.com',
	customerServiceInformationUrl: 'https://e-mobility.abb.com/smart-charge-at-home/'
};
