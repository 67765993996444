import React, { ReactNode } from 'react';

import { Tag } from '../tag/Tag';

import './PrimarySubject.scss';

type PrimarySubjectProps = {
	children: ReactNode,
	tags?: Array<string>
};

export const PrimarySubject = (props: PrimarySubjectProps) => {
	const { children, tags } = props;

	const renderTags = (): ReactNode => {
		if (tags) {
			return (
				<section className="primary-subject__footer">
					{
						tags.map((tag, index) => {
							return (
								<Tag label={tag} key={index} />
							);
						})
					}
				</section>
			);
		}

		return;
	};

	return (
		<article className="primary-subject">
			<section className="primary-subject__items">
				{children}
			</section>
			{renderTags()}
		</article>
	);
};
