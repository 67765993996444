import fetchMock from 'fetch-mock';

export const preconfigureFetchMock = () => {

	fetchMock.config.sendAsJson = false;
	fetchMock.config.overwriteRoutes = false;
	fetchMock.config.fallbackToNetwork = true;
	fetchMock.config.includeContentLength = false;
	fetchMock.config.warnOnFallback = false;

};
