import React, { ReactNode } from 'react';

import { IconIdentifier } from '../icon/Icon.enums';
import IconSvg from '../icon/IconSvg';

import './PrimarySubjectItem.scss';

type PrimarySubjectItemProps = {
	icon?: IconIdentifier,
	label: string,
	additionalInformation?: ReactNode
}

export const PrimarySubjectItem = (props: PrimarySubjectItemProps) => {
	const { label, icon, additionalInformation } = props;

	const renderIcon = (): ReactNode => {
		if (icon) {
			return (
				<div className="primary-subject-item__icon">
					<IconSvg name={icon} />
				</div>
			);
		}

		return;
	};

	const renderAdditionalInformation = (): ReactNode => {
		if (additionalInformation) {
			return (
				<span>{additionalInformation}</span>
			);
		}
		return;
	};

	return (
		<section className="primary-subject-item">
			{renderIcon()}
			<div className="primary-subject-item__content">
				<span>{label}</span>
				{renderAdditionalInformation()}
			</div>
		</section>
	);
};
