import fetchMock from 'fetch-mock';

import { AnyCustomerOrderInformationModel } from '@abb-emobility/oms/domain-model';
import {
	preconfigureFetchMock,
	createMockBeginMatcher,
	createMockErrorResponse,
	createMockOptions,
	createMockResponse
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { AnyCustomerOrderInformationApiClient } from './AnyCustomerOrderInformationApiClient';
import {
	customerOrderInformationAppointmentModelMock,
	customerOrderInformationOfferModelMock,
	customerOrderInformationOrderItemsModelMock,
	customerOrderInformationShippingModelMock
} from '../../mock-data/customer/AnyCustomerOrderInformationModel.mock';


export class AnyCustomerOrderInformationApiClientMock extends AnyCustomerOrderInformationApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		const orderInformationModelMocks: Array<Dto<AnyCustomerOrderInformationModel>> = [
			customerOrderInformationOrderItemsModelMock,
			customerOrderInformationOfferModelMock,
			customerOrderInformationAppointmentModelMock,
			customerOrderInformationShippingModelMock
		];

		for (const orderInformationModelMock of orderInformationModelMocks) {
			fetchMock
				.get(
					createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint(orderInformationModelMock.id), true),
					createMockResponse(orderInformationModelMock),
					createMockOptions()
				)
				.get(
					createMockBeginMatcher(this.getUrlBuilder().buildEntityEndpoint(orderInformationModelMock.id)),
					createMockErrorResponse(401),
					createMockOptions()
				);
		}
	}

}
