import { ReactNode, useState } from 'react';

import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { LocalStorage } from '@abb-emobility/shared/local-storage';

import { TermsAcceptance } from '../terms-acceptance/TermsAcceptance';

export type AcceptanceInterceptorProps = {
	children: ReactNode,
	localStorageKey: string,
	renderTerms: () => ReactNode
};

export function AcceptanceInterceptor(props: AcceptanceInterceptorProps) {

	const { children, localStorageKey, renderTerms } = props;

	const identity = useIdentity();
	const userId = identity.getIdentity().get()?.userId ?? undefined;
	const [accepted, setAccepted] = useState<boolean>(LocalStorage.read<boolean>(localStorageKey, { namespace: userId }).getOrDefault(false));

	const accept = (): void => {
		LocalStorage.write(localStorageKey, true, { namespace: userId });
		setAccepted(true);
	};

	if (accepted || process.env['NX_SHARED_ACCEPTANCE_INTERCEPTOR_MOCKED'] === 'true') {
		return children;
	}

	return (
		<TermsAcceptance onAccept={accept}>
			{renderTerms()}
		</TermsAcceptance>
	);
}
