import { InstallationPartnerOrderInformationBasicAnswersInformationModel } from '@abb-emobility/oms/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';
import {
	basicQuestionsQuestionnaireGermanyAnswersMock,
	basicQuestionsQuestionnaireDeModelMock,
	questionnaireEnvironmentModelMock
} from '@abb-emobility/shared/questionnaire';

export const installationPartnerOrderInformationBasicAnswersInformationModelMock = (): Dto<InstallationPartnerOrderInformationBasicAnswersInformationModel> => {
	return {
		questionnaire: basicQuestionsQuestionnaireDeModelMock(),
		questionnaireEnvironment: questionnaireEnvironmentModelMock,
		answers: basicQuestionsQuestionnaireGermanyAnswersMock
	};
};
