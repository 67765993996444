import fetchMock from 'fetch-mock';

import {
	createMockBeginMatcher,
	createMockOptions,
	createMockResponse, preconfigureFetchMock
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { InstallationPartnerFiscalReportApiClient } from './InstallationPartnerFiscalReportApiClient';
import {
	installationPartnerFiscalReportCollectionMock, installationPartnerFiscalReportModelMock
} from '../../mock-data/installation-partner/InstallationPartnerFiscalReportModel.mock';

export class InstallationPartnerFiscalReportApiClientMock extends InstallationPartnerFiscalReportApiClient {

	override init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		super.init(baseUrl, jsonWebToken);
		return this;
	}

	public configureFetchMock() {
		preconfigureFetchMock();

		fetchMock
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint()), createMockResponse(installationPartnerFiscalReportCollectionMock(), 200, [
				{ key: 'X-Pagination-Page', values: ['1'] },
				{ key: 'X-Pagination-Max-Pages', values: ['3'] },
				{ key: 'X-Pagination-Total-Items', values: ['12'] }
			]), createMockOptions())
			.get(createMockBeginMatcher(this.getUrlBuilder().buildCollectionEndpoint(), true), createMockResponse(installationPartnerFiscalReportModelMock), createMockOptions());
	}

}
