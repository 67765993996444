import { InputHTMLAttributes } from 'react';

import './InputToggle.scss';

export type InputToggleProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export function InputToggle(props: InputHTMLAttributes<HTMLInputElement>) {
	const { ...rest } = props;

	return (
		<label className="input-toggle">
			<input className="input-toggle__input" type="checkbox" {...rest} />
			<span className="input-toggle__slider" />
		</label>
	);
}
