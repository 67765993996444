import React, { useEffect } from 'react';

import { KeycloakApiClientFactory } from '@abb-emobility/shared/keycloak-integration';
import {
	AppLayout,
	AppLayoutMain,
	AppLayoutMainContent,
	AppLayoutMainHeader,
	SpinnerCircle,
	Topbar
} from '@abb-emobility/shared/ui-primitive';

import { OauthEmitterProps } from './OauthEmitter';

export function OauthEmitterMock<AdditionalTokenResponse = unknown>(props: OauthEmitterProps<AdditionalTokenResponse>) {

	const { onAuthenticate = null, onTokenReceived = null } = props;

	useEffect(() => {
		const auth = async () => {
			const tokenResponse = await KeycloakApiClientFactory.create()
				.requestToken<AdditionalTokenResponse>('', '', '', '', '');
			if (onAuthenticate !== null) {
				const accessTokenValidTo = new Date();
				accessTokenValidTo.setSeconds(accessTokenValidTo.getSeconds() + tokenResponse.expires_in);
				const refreshTokenValidTo = new Date();
				refreshTokenValidTo.setSeconds(refreshTokenValidTo.getSeconds() + tokenResponse.refresh_expires_in);
				onAuthenticate(
					tokenResponse.access_token,
					accessTokenValidTo,
					tokenResponse.refresh_token,
					refreshTokenValidTo,
					tokenResponse.roles
				);
			}
			if (onTokenReceived !== null) {
				onTokenReceived(tokenResponse);
			}
		};
		void auth();
	}, []);

	return (
		<AppLayout>
			<AppLayoutMain>
				<AppLayoutMainHeader>
					<Topbar />
				</AppLayoutMainHeader>
				<AppLayoutMainContent>
					<SpinnerCircle />
				</AppLayoutMainContent>
			</AppLayoutMain>
		</AppLayout>
	);

}
