import { AnyCustomerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerOrderInformationAppointmentModelMock,
	customerOrderInformationOfferModelMock,
	customerOrderInformationOrderItemsModelMock,
	customerOrderInformationShippingModelMock
} from './AnyCustomerOrderInformationModel.mock';

export const anyCustomerOrderInformationCollectionItemModelMock: Array<Dto<AnyCustomerOrderInformationCollectionItemModel>> = [
	{
		id: customerOrderInformationOrderItemsModelMock.id,
		orderId: customerOrderInformationOrderItemsModelMock.orderId,
		type: customerOrderInformationOrderItemsModelMock.type,
		createdAt: customerOrderInformationOrderItemsModelMock.createdAt
	},
	{
		id: customerOrderInformationOfferModelMock.id,
		orderId: customerOrderInformationOfferModelMock.orderId,
		type: customerOrderInformationOfferModelMock.type,
		createdAt: customerOrderInformationOfferModelMock.createdAt
	},
	{
		id: customerOrderInformationAppointmentModelMock.id,
		orderId: customerOrderInformationAppointmentModelMock.orderId,
		type: customerOrderInformationAppointmentModelMock.type,
		createdAt: customerOrderInformationAppointmentModelMock.createdAt
	},
	{
		id: customerOrderInformationShippingModelMock.id,
		orderId: customerOrderInformationShippingModelMock.orderId,
		type: customerOrderInformationShippingModelMock.type,
		createdAt: customerOrderInformationShippingModelMock.createdAt
	}
];
