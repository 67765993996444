import './InformationInstallationEmptyLogo.scss';

type InformationInstallationEmptyLogoProps = {
	source: string
};

export function InformationInstallationEmptyLogo(props: InformationInstallationEmptyLogoProps) {
	const { source } = props;

	return (
		<img className="information-installation-empty-logo" src={source} alt="" />
	);
}
